import axios from "axios";
import { commercialCloud } from "../../../Config/appConstant";
import config from "../../../Config/Config";


export const getAccountsList = (selectedCloud, token) => new Promise((resolve,reject)=>{
    const selectedUrl = selectedCloud?.toLowerCase() === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
        headers: {
            'Authorization': token
        }
    };
    axios.get(selectedUrl + 'account/account-list', options)
        .then(res => {
            resolve(res)
        })
        .catch(function (error) {
            reject(error)
        })
})

export const createIamUser = (selectedCloud, token, payload) => new Promise((resolve, reject)=>{
    const options = {
        headers: {
            'Authorization': token
        }
    };

    selectedCloud?.toLowerCase() === commercialCloud ?(
        axios.post(config.apiendpoint + 'iam-user', payload, options)
        .then(res => {
            resolve(res)
        })
        .catch(function (error) {
            reject(error)
        })
    ) : (
        axios.post(config.govCloudApi + 'iam-user', payload, options)
        .then(res => {
            resolve(res)
        })
        .catch(function (error) {
            reject(error)
        })
    )
    
})

export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatDateTime = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};