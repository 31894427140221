import React from 'react'
import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Header,
  Image,
  Modal,
} from 'semantic-ui-react'

function CustomModalWrapper({openModal, closeModal,title,children}) {
  const [open, setOpen] = React.useState(openModal)

  return (
    <Modal
      open={openModal}
      size="large"
    >
      <ModalHeader style={{backgroundColor:"rgb(42, 125, 225)",color:"#fff"}}>{title}</ModalHeader>
      <ModalContent>
       <div style={{ width:"100%",textAlign:"center", margin:"30px 0 30px 0"}}>
       {children}
       </div>
      
      <div style={{display:"flex",flexDirection:"row-reverse"}}>
      <Button onClick={() => closeModal()}>
          Cancel
        </Button>
      </div>
      </ModalContent>
     
    </Modal>
  )
}

export default CustomModalWrapper