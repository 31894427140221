import React from 'react';
import { Modal, Button, Icon, Form } from 'semantic-ui-react';

const DeleteConfirmationModal = ({
    isOpen,
    onClose,
    onConfirm,
    username,
    confirmUsername,
    setConfirmUsername,
    confirmError,
    setConfirmError
}) => {
    const handleConfirm = () => {
        if (confirmUsername === '') {
            setConfirmError('Please enter the username');
            return;
        }
        if (confirmUsername !== username) {
            setConfirmError('Username does not match');
            return;
        }
        onConfirm();
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onClose();
                setConfirmUsername('');
                setConfirmError('');
            }}
            size="tiny"
        >
            <Modal.Header>
                <Icon name="warning sign" color="red" />
                Confirm User Deletion
            </Modal.Header>
            <Modal.Content>
                <p>You are about to delete the user: <strong>{username}</strong></p>
                <p>This action cannot be undone. Please type the username to confirm deletion:</p>
                <Form.Input
                    fluid
                    placeholder="Enter username to confirm"
                    value={confirmUsername}
                    onChange={(e) => {
                        setConfirmUsername(e.target.value);
                        setConfirmError('');
                    }}
                    error={confirmError ? {
                        content: confirmError,
                        pointing: 'above'
                    } : null}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button  onClick={() => {
                    onClose();
                    setConfirmUsername('');
                    setConfirmError('');
                }}>
                    <Icon name='remove' /> Cancel
                </Button>
                <Button color='red' onClick={handleConfirm}>
                    <Icon name='checkmark' /> Confirm Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteConfirmationModal;
