import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Form,
    Button,
    Header,
    Segment,
    Grid,
    Dropdown,
    Dimmer,
    Loader,
    TextArea,
    Icon
} from 'semantic-ui-react';
import crypto from 'crypto';
import { createIamUser, formatDate, formatDateTime } from '../service';
import ExecutionStatusTracker from '../../../Modal/StepFunctionTracker';
import Modal from '../../../Modal/CustomModalWrapper';
import CredentialsDisplay from './CredentialsDisplay';

// Function to validate AWS policy JSON
const isValidPolicyDocument = (policyString) => {
    try {
        const policy = JSON.parse(policyString);

        // Basic policy structure validation
        if (!policy.Version || !policy.Statement) {
            return false;
        }

        // Validate Statement array
        if (!Array.isArray(policy.Statement)) {
            return false;
        }

        // Check if each statement has required fields
        const validStatement = policy.Statement.every(statement =>
            statement.Effect &&
            (statement.Action || statement.NotAction) &&
            (statement.Resource || statement.NotResource)
        );

        return validStatement;
    } catch (error) {
        return false;
    }
};

const styles = {
    mainContainer: {

    },
    headerSegment: {
        backgroundColor: '#f7f7f7',
        padding: '1.2rem',
        marginBottom: '2rem',
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0',
        width: "92%",
        marginLeft: "4%"

    },
    formContainer: {
        backgroundColor: '#ffffff',
        padding: '2rem',
        borderRadius: '4px',
    },
    input: {
        backgroundColor: '#f7f7f7',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        padding: '10px',
        width: '100%',
    },
    dropdown: {
        backgroundColor: '#f7f7f7 !important',
        border: '1px solid #e0e0e0 !important',
    },
    textArea: {
        backgroundColor: '#f7f7f7',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        padding: '10px',
        width: '100%',
        minHeight: '200px',
        fontFamily: 'monospace',
    },
    submitButton: {
        marginTop: '2rem',
    },
    errorLabel: {
        marginTop: '5px',
        color: '#db2828',
    }
};

function decryptAesCbc(encryptedData, secretToken) {
    try {

        // Validate input
        if (!encryptedData) {
            throw new Error('No data to decrypt');
        }

        const encryptedBytes = Buffer.from(encryptedData, 'base64');
        if (encryptedBytes.length < 17) { // At least 16 bytes for IV + 1 byte data
            throw new Error('Invalid encrypted data length');
        }

        const iv = encryptedBytes.slice(0, 16);
        const ciphertext = encryptedBytes.slice(16);

        const decipher = crypto.createDecipheriv('aes-256-cbc', secretToken, iv);
        let decrypted = decipher.update(ciphertext);
        decrypted = Buffer.concat([decrypted, decipher.final()]);

        return decrypted.toString('utf8');
    } catch (error) {
        throw new Error(`Decryption failed: ${error.message}`);
    }
}


const PolicyForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [executionArn, setExecutionArn] = useState(localStorage.getItem("USER_CREATION_ARN") || "");
    const [credentials, setCredentials] = useState(null);

    const initialValues = {
        accountName: '',
        username: '',
        policyDocument: "",
        serviceNowTicketId: '',
    };

    const validationSchema = Yup.object().shape({
        accountName: Yup.string()
            .required('Account name is required'),
        username: Yup.string()
            .required('Username is required')
            .min(3, 'Username must be at least 3 characters')
            .max(64, 'Username cannot exceed 64 characters'),
        policyDocument: Yup.string()
            .required('Policy document is required')
            .test('is-valid-policy', 'Invalid AWS policy document', value => {
                if (!value) return false;
                return isValidPolicyDocument(value);
            }),
        serviceNowTicketId: Yup.string()
            .required('ServiceNow ID is required')
            .min(11, "ServiceNow ID must be exactly 11 letters long")
            .max(11, "ServiceNow ID must be exactly 11 letters long")
            .matches(/^[a-zA-Z0-9,-]*$/, "ServiceNow ID should not contain spaces")
            .test(
                "RITM",
                "ServiceNow ID should start with RITM",
                val => val && val.startsWith("RITM")
            )
            .test(
                "RITMNUM",
                "ServiceNow ID should contain digits after RITM",
                val => val && !isNaN(val.substring(4))
            )
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            setLoading(true);
            let findAccount = props?.accountOptions?.data?.find((item) => item?.value == values?.accountName);
            // Add your API call here
            const today = new Date();
            const approvalDate = formatDate(today);

            const expiryDate = new Date(today);
            expiryDate.setFullYear(expiryDate.getFullYear() + 1);
            const formattedExpiryDate = formatDate(expiryDate);

            let payload =
            {
                "Policy": values?.policyDocument,
                "ExpiryDate": formattedExpiryDate,
                "AccountId": values?.accountName,
                "UserName": values?.username,
                "ServiceNowTicketId": values?.serviceNowTicketId,
                "Operation": "UserCreation",
                "RequestedBy": props?.username?.login,
                "CreateTimeStamp": formatDateTime(today),
                "UpdateTimeStamp": formatDateTime(today),
                "AccountName": findAccount?.accountName,
                "ApprovalDate": approvalDate
            }

            let response = await createIamUser(props?.selectCloud, props.authState.accessToken.accessToken, payload)

            if (response?.data?.status === "success") {
                setModalMessage('User created successfully!');
                localStorage.setItem('USER_CREATION_ARN', response?.data?.data?.executionArn);
                setExecutionArn(response?.data?.data?.executionArn);
                resetForm();
            } else {
                setModalMessage(response.message || 'An error occurred while creating the policy.');
            }
        } catch (error) {
            console.error(error)
            setModalMessage((error?.response?.data?.data?.error || response.message )|| 'An unexpected error occurred.');
        } finally {
            setLoading(false);
            setSubmitting(false);
            setOpen(true);
        }
    };

    const handleResponseData = (item) => {

        if (item?.status === "SUCCEEDED" && item?.steps) {
            const { AccessKey, SecretKey } = JSON.parse(item?.steps[item?.steps?.length - 1]?.output) || {}
            
            const secretKey = process.env.REACT_APP_IAM_SECRECT_KEY
            setCredentials({ accessToken:decryptAesCbc(AccessKey,secretKey), secretKey: decryptAesCbc(SecretKey,secretKey) })
        }
        else {
            setCredentials(null)
        }
    }

    return (
        <Container fluid style={styles.mainContainer}>
            <Dimmer active={props?.accountOptions?.isLoading || loading} inverted>
                <Loader>Please wait...</Loader>
            </Dimmer>

            <Grid centered>
                <Grid.Column mobile={16} tablet={12} computer={8} >
                    <Segment centered basic style={styles.headerSegment}>
                        <Header as="h2" color='blue' textAlign="center">
                            Create IAM User
                        </Header>
                    </Segment>


                    <div style={styles.formContainer}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                            }) => (
                                <Form size="large" onSubmit={handleSubmit}>
                                    <Form.Field
                                        error={touched.accountName && errors.accountName}
                                    >
                                        <label>Account Name</label>
                                        <Dropdown
                                            id="policy-account-name"
                                            placeholder="Select Account"
                                            fluid
                                            search
                                            selection
                                            options={props?.accountOptions?.data?.map((item) => ({
                                                key: item?.key,
                                                text: item?.text,
                                                value: item?.value
                                            }))}
                                            value={values.accountName}
                                            onChange={(_, { value }) => setFieldValue('accountName', value)}
                                            onBlur={handleBlur}
                                            name="accountName"
                                            style={styles.dropdown}
                                            searchInput={{ name: 'account-search' }}
                                            loading={props?.accountOptions?.isLoading}
                                        />
                                        {touched.accountName && errors.accountName && (
                                            <div className="ui pointing red basic label">
                                                {errors.accountName}
                                            </div>
                                        )}
                                    </Form.Field>
                                    <Grid columns={2} stackable>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Form.Field
                                                    error={touched.username && errors.username}
                                                >
                                                    <label>Username</label>
                                                    <input
                                                        id="policy-username"
                                                        placeholder="Enter username"
                                                        type="text"
                                                        name="username"
                                                        value={values.username}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={styles.input}
                                                    />
                                                    {touched.username && errors.username && (
                                                        <div className="ui pointing red basic label">
                                                            {errors.username}
                                                        </div>
                                                    )}
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Form.Field
                                                    error={touched.serviceNowTicketId && errors.serviceNowTicketId}
                                                >
                                                    <label>ServiceNow Ticket ID</label>
                                                    <input
                                                        id="policy-servicenow-id"
                                                        placeholder="Enter ServiceNow ID (e.g., INC0000123)"
                                                        type="text"
                                                        name="serviceNowTicketId"
                                                        value={values.serviceNowTicketId}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={styles.input}
                                                    />
                                                    {touched.serviceNowTicketId && errors.serviceNowTicketId && (
                                                        <div className="ui pointing red basic label">
                                                            {errors.serviceNowTicketId}
                                                        </div>
                                                    )}
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Form.Field
                                        style={{ marginTop: "20px" }}
                                        error={touched.policyDocument && errors.policyDocument}
                                    >
                                        <label>Policy Document</label>
                                        <TextArea
                                            id="policy-document"
                                            placeholder={`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": ["service:Action"],
      "Resource": ["arn:aws:service:region:account-id:resource"]
    }
  ]
}`}
                                            name="policyDocument"
                                            value={values.policyDocument}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={styles.textArea}
                                        />
                                        {touched.policyDocument && errors.policyDocument && (
                                            <div className="ui pointing red basic label">
                                                {errors.policyDocument}
                                            </div>
                                        )}
                                    </Form.Field>

                                    <Button.Group fluid style={styles.submitButton}>
                                        <Button
                                            type="button"
                                            size="large"
                                            onClick={() => {
                                                Object.keys(values).forEach(key => {
                                                    setFieldValue(key, initialValues[key]);
                                                });
                                            }}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            type="submit"
                                            primary
                                            size="large"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Button.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Grid.Column>
            </Grid>
            <Modal

                openModal={open}
                title={<><Icon name="tasks" /> IAM User Creation Status</>}
                closeModal={() => {
                    setOpen(false)
                    setCredentials(null)
                    localStorage.removeItem('USER_CREATION_ARN');
                }}
            >

                <ExecutionStatusTracker
                    executionArn={executionArn}
                    selectCloud={props?.selectCloud}
                    token={props.authState.accessToken.accessToken}
                    getResponseData={handleResponseData}
                    message="IAM User created successfully!"
                    errorMsg={modalMessage}
                    errorModelOpen = {()=>setOpen(false)}
                />

                {credentials && (
                    <CredentialsDisplay
                        accessToken={credentials.accessToken}
                        secretKey={credentials.secretKey}
                    />
                )}
            </Modal>
        </Container>
    );
};

export default PolicyForm;
