import React, { useState, useEffect } from 'react'
import PolicyForm from './UserForm'
import { Segment } from 'semantic-ui-react'

import { getAccountsList } from '../service'
import { localStorageCloudValue } from '../../../../Config/appConstant'
import CloudModal from './../CloudModal'
export default function UserLayout(props) {
  const [selectCloud, setSelectCloud] = useState("")
  const [openModal, setOpenModal] = useState(true)
  const [accounts, setAccounts] = useState({ data: [], isLoading: false, errMsg: null })
  const [account, setAccount] = useState([])
  const [message, setMessage] = useState({ color: 'red', message: "", active: false })

  useEffect(() => {
    let value = localStorage.getItem(localStorageCloudValue)
    if (value !== null && value !== "") {
      setSelectCloud(value)
      setOpenModal(false)
    }
  }, [])
  const getAccounts = () => {
    setAccounts({ data: [], isLoading: true, errMsg: null })
    getAccountsList(localStorage.getItem(localStorageCloudValue), props?.authState?.accessToken?.accessToken).then((result) => {
      setAccounts({ data: result?.data?.body?.data, isLoading: false, errMsg: null })
    }).catch(err => {
      console.error(err)
      setAccounts({ data: [], isLoading: false, errMsg: err?.message })
      setMessage({ color: 'red', message: "Something went wrong!", active: true })
    })
  }

  useEffect(() => {
    if (props.authState.accessToken.accessToken) {
      getAccounts()
    }
  }, [selectCloud])
  return (
    <Segment
      size="mini"
      style={{
        padding: "30px",
        borderColor: "blue",
        borderStyle: "Solid",
        margin: "6px",
      }}>
      <CloudModal openModal={openModal} selectCloud={selectCloud} setOpenModal={setOpenModal} setSelectCloud={setSelectCloud} />
      <PolicyForm accountOptions={accounts} {...props} selectCloud={selectCloud}/>
    </Segment>
  )
}
