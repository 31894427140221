import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Form,
    Button,
    Header,
    Segment,
    Grid,
    Dropdown,
    Dimmer,
    Loader,
    TextArea,
    Icon
} from 'semantic-ui-react';
import { createIamUser as deleteIamUser, formatDateTime } from '../service';
import ExecutionStatusTracker from '../../../Modal/StepFunctionTracker';
import Modal from '../../../Modal/CustomModalWrapper';
import DeleteConfirmationModal from './DeleteConfirmationModal';
const styles = {
    mainContainer: {

    },
    headerSegment: {
        backgroundColor: '#f7f7f7',
        padding: '1.2rem',
        marginBottom: '2rem',
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0',
        width: "92%",
        marginLeft: "4%"

    },
    formContainer: {
        backgroundColor: '#ffffff',
        padding: '2rem',
        borderRadius: '4px',
    },
    input: {
        backgroundColor: '#f7f7f7',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        padding: '10px',
        width: '100%',
    },
    dropdown: {
        backgroundColor: '#f7f7f7 !important',
        border: '1px solid #e0e0e0 !important',
    },
    textArea: {
        backgroundColor: '#f7f7f7',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        padding: '10px',
        width: '100%',
        minHeight: '200px',
        fontFamily: 'monospace',
    },
    submitButton: {
        marginTop: '2rem',
    },
    errorLabel: {
        marginTop: '5px',
        color: '#db2828',
    }
};

const PolicyForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [executionArn, setExecutionArn] = useState(localStorage.getItem("USER_CREATION_ARN") || "");
    const [credentials, setCredentials] = useState(null);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmUsername, setConfirmUsername] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const [formValues, setFormValues] = useState(null);
    const [formReset,setFormReset] = useState({onReset:()=>{}})

    const initialValues = {
        accountName: '',
        username: '',
        serviceNowTicketId: '',
    };

    const validationSchema = Yup.object().shape({
        accountName: Yup.string()
            .required('Account name is required'),
        username: Yup.string()
            .required('Username is required')
            .min(3, 'Username must be at least 3 characters')
            .max(64, 'Username cannot exceed 64 characters'),
        serviceNowTicketId: Yup.string()
            .required('ServiceNow ID is required')
            .min(11, "ServiceNow ID must be exactly 11 letters long")
            .max(11, "ServiceNow ID must be exactly 11 letters long")
            .matches(/^[a-zA-Z0-9,-]*$/, "ServiceNow ID should not contain spaces")
            .test(
                "RITM",
                "ServiceNow ID should start with RITM",
                val => val && val.startsWith("RITM")
            )
            .test(
                "RITMNUM",
                "ServiceNow ID should contain digits after RITM",
                val => val && !isNaN(val.substring(4))
            )
    });

    const handleSubmit = async (values, {resetForm,setSubmitting}) => {
        if (!showConfirmation) {
            setFormValues(values);
            setShowConfirmation(true);
            setSubmitting(false);
            setFormReset({onReset:resetForm})
            return;
        }

        try {
            setLoading(true);
            let findAccount = props?.accountOptions?.data?.find((item) => item?.value == values?.accountName);
            
            const todayDate = new Date();
            let payload = {
                "Policy": "",
                "ExpiryDate": "",
                "AccountId": values?.accountName,
                "UserName": values?.username,
                "ServiceNowTicketId": values?.serviceNowTicketId,
                "Operation": "UserDeletion",
                "RequestedBy": props?.username?.login,
                "CreateTimeStamp": "",
                "UpdateTimeStamp": formatDateTime(todayDate),
                "AccountName": findAccount?.accountName,
                "ApprovalDate": ""
            };

            let response = await deleteIamUser(props?.selectCloud, props.authState.accessToken.accessToken, payload);

            if (response?.data?.status === "success") {
                setModalMessage('User deleted successfully!');
                localStorage.setItem('USER_CREATION_ARN', response?.data?.data?.executionArn);
                setExecutionArn(response?.data?.data?.executionArn);
                resetForm()
            } else {
                setModalMessage(response.message || 'An error occurred while deleting the user.');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            setModalMessage(error.message || 'An unexpected error occurred.');
        } finally {
            setLoading(false);
            setSubmitting(false);
            setOpen(true);
            setShowConfirmation(false);
            setConfirmUsername('');
            setConfirmError('');
        }
    };

    const handleConfirmDelete = () => {
        if (formValues) {
            handleSubmit(formValues, { setSubmitting: () => {}, resetForm: () => formReset?.onReset()});
        }
    };

    const handleResponseData = (item) => {

        if (item?.status === "SUCCEEDED" && item?.steps) {
            const { AccessKey, SecretKey } = JSON.parse(item?.steps[item?.steps?.length - 1]?.output) || {}
            setCredentials({ accessToken: AccessKey, secretKey: SecretKey })
        }
        else {
            setCredentials(null)
        }
    }

    return (
        <Container fluid style={styles.mainContainer}>
            <Dimmer active={props?.accountOptions?.isLoading || loading} inverted>
                <Loader>Please wait...</Loader>
            </Dimmer>

            <Grid centered>
                <Grid.Column mobile={16} tablet={12} computer={8} >
                    <Segment centered basic style={styles.headerSegment}>
                        <Header as="h2" color='blue' textAlign="center">
                            Delete IAM User
                        </Header>
                    </Segment>
                    <div style={styles.formContainer}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                                resetForm
                            }) => (
                                <Form size="large" onSubmit={handleSubmit}>
                                    <Form.Field
                                        error={touched.accountName && errors.accountName}
                                    >
                                        <label>Account Name</label>
                                        <Dropdown
                                            id="policy-account-name"
                                            placeholder="Select Account"
                                            fluid
                                            search
                                            selection
                                            options={props?.accountOptions?.data?.map((item) => ({
                                                key: item?.key,
                                                text: item?.text,
                                                value: item?.value
                                            }))}
                                            value={values.accountName}
                                            onChange={(_, { value }) => setFieldValue('accountName', value)}
                                            onBlur={handleBlur}
                                            name="accountName"
                                            style={styles.dropdown}
                                            searchInput={{ name: 'account-search' }}
                                            loading={props?.accountOptions?.isLoading}
                                        />
                                        {touched.accountName && errors.accountName && (
                                            <div className="ui pointing red basic label">
                                                {errors.accountName}
                                            </div>
                                        )}
                                    </Form.Field>
                                    <Grid columns={2} stackable>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Form.Field
                                                    error={touched.username && errors.username}
                                                >
                                                    <label>Username</label>
                                                    <input
                                                        id="policy-username"
                                                        placeholder="Enter username"
                                                        type="text"
                                                        name="username"
                                                        value={values.username}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={styles.input}
                                                    />
                                                    {touched.username && errors.username && (
                                                        <div className="ui pointing red basic label">
                                                            {errors.username}
                                                        </div>
                                                    )}
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Form.Field
                                                    error={touched.serviceNowTicketId && errors.serviceNowTicketId}
                                                >
                                                    <label>ServiceNow Ticket ID</label>
                                                    <input
                                                        id="policy-servicenow-id"
                                                        placeholder="Enter ServiceNow ID (e.g., INC0000123)"
                                                        type="text"
                                                        name="serviceNowTicketId"
                                                        value={values.serviceNowTicketId}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={styles.input}
                                                    />
                                                    {touched.serviceNowTicketId && errors.serviceNowTicketId && (
                                                        <div className="ui pointing red basic label">
                                                            {errors.serviceNowTicketId}
                                                        </div>
                                                    )}
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Button.Group fluid style={styles.submitButton}>

                                        <Button
                                            type="button"
                                            size="large"
                                            onClick={() => {
                                                resetForm()

                                            }}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Reset
                                        </Button>

                                        <Button
                                            type="submit"
                                            primary
                                            size="large"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        >
                                            Submit
                                        </Button>

                                    </Button.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Grid.Column>
            </Grid>
            <Modal
                openModal={open}
                title={<><Icon name="tasks" /> IAM User Deletion Status</>}
                closeModal={() => {
                    setOpen(false)
                    localStorage.removeItem('USER_CREATION_ARN');
                }}
            >

                <ExecutionStatusTracker
                    executionArn={executionArn}
                    selectCloud={props?.selectCloud}
                    token={props.authState.accessToken.accessToken}
                    getResponseData={handleResponseData}
                    message="IAM User deleted successfully!"
                    errorMsg="Failed to delete IAM User!"
                    errorModelOpen={()=>setOpen(false)}
                />
            </Modal>
            <DeleteConfirmationModal 
                isOpen={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                onConfirm={handleConfirmDelete}
                username={formValues?.username || ''}
                confirmUsername={confirmUsername}
                setConfirmUsername={setConfirmUsername}
                confirmError={confirmError}
                setConfirmError={setConfirmError}
            />
        </Container>
    );
};

export default PolicyForm;
