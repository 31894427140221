import React from 'react'
import { Select, Form, Radio } from 'semantic-ui-react'
import { cloudCompatibilityOption, commercialCloud, labelAws, labelAwsUsGov, localStorageCloudValue, usGovCloud } from './../../../Config/appConstant'
import Modal from '../../Modal'

export default function CloudModal({openModal,selectCloud,setOpenModal,setSelectCloud}) {

    const handleSelectCloudSpace = (event, data) => {
        setOpenModal(false)
        setSelectCloud(data.value)
        localStorage.setItem(localStorageCloudValue,data?.value)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        window.location.href = "/"
    }

    return (
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px", flexDirection: "row-reverse" }}>

            <div>
                <b style={{ fontSize: 13, marginBottom: 2 }}>Selected organization:</b>
                <Select
                    fluid
                    label='Select Cloud'
                    options={cloudCompatibilityOption}
                    value={selectCloud}
                    name="selectCloud"
                    onChange={handleSelectCloudSpace}
                    placeholder='Select Cloud'
                    style={{ position: "centre", width: "150px" }}
                />
            </div>

            <Modal openModal={openModal} closeModal={handleCloseModal} title={'Choose organization:'}>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Radio
                                label={labelAws.label}
                                name="selectCloud"
                                value={labelAws.value}
                                checked={selectCloud === commercialCloud}
                                onChange={handleSelectCloudSpace}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label={labelAwsUsGov.label}
                                name="selectCloud"
                                value={labelAwsUsGov.value}
                                checked={selectCloud === usGovCloud}
                                onChange={handleSelectCloudSpace}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal>
        </div>
    )
}