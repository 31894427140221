import React, { useState } from 'react';
import {
  Card,
  Button,
  Icon,
  Header,
  Segment,
  Label,
  Grid,
  Message
} from 'semantic-ui-react';

const CredentialsDisplay = ({ accessToken, secretKey }) => {
  const [accessTokenCopied, setAccessTokenCopied] = useState(false);
  const [secretKeyCopied, setSecretKeyCopied] = useState(false);

  const handleCopy = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      if (type === 'access') {
        setAccessTokenCopied(true);
        setTimeout(() => setAccessTokenCopied(false), 2000);
      } else {
        setSecretKeyCopied(true);
        setTimeout(() => setSecretKeyCopied(false), 2000);
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const handleDownloadCSV = () => {
    // Create CSV content
    const csvContent = [
      ['Access Key ID', 'Secret Access Key'],
      [accessToken, secretKey]
    ]
      .map(row => row.join(','))
      .join('\n');

    // Create blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    
    // Create object URL
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'aws-credentials.csv');
    
    // Append to document, trigger download, and cleanup
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const maskSecretKey = (key) => {
    if (!key) return '';
    const visibleChars = 5; // Number of characters to show at the start
    const firstPart = key.substring(0, visibleChars);
    const maskedPart = '*'.repeat(key.length - visibleChars);
    return `${firstPart}${maskedPart}`;
  };

  return (
    <Segment padded>
      <Header as="h2" color="blue">
        <Icon name="key" />
        <Header.Content>
          User Credentials
        </Header.Content>
      </Header>

      <Message warning>
        <Message.Header>Important Security Notice</Message.Header>
        <p>Please save these credentials immediately. The secret key will not be shown again.</p>
      </Message>
      

      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            <Card fluid>
              <Card.Content style={{ textAlign: "left" }}>
                <label>Access Key ID</label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <Segment secondary style={{
                    wordBreak: 'break-all',
                    fontFamily: 'monospace',
                    fontSize: '1.1em',
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: '10px',
                    marginBottom: 0,
                    width: "100%"
                  }}>
                    <div>{maskSecretKey(accessToken)}</div>
                    <Icon
                      name={accessTokenCopied ? 'check' : 'copy'}
                      color={accessTokenCopied ? 'green' : 'blue'}
                      link
                      onClick={() => handleCopy(accessToken, 'access')}
                      style={{ cursor: 'pointer' }}
                    />
                  </Segment>

                </div>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={8}>
            <Card fluid>
              <Card.Content style={{ textAlign: "left" }}>
                <label>Secret Access Key</label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <Segment secondary style={{
                    wordBreak: 'break-all',
                    fontFamily: 'monospace',
                    fontSize: '1.1em',
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: '10px',
                    marginBottom: 0,
                    width: "100%"
                  }}>
                    <div>
                      {maskSecretKey(secretKey)}
                    </div>
                    <Icon
                      name={secretKeyCopied ? 'check' : 'copy'}
                      color={secretKeyCopied ? 'green' : 'red'}
                      link
                      onClick={() => handleCopy(secretKey, 'secret')}
                      style={{ cursor: 'pointer' }}
                    />
                  </Segment>

                </div>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div style={{marginTop:"20px", display:"flex"}}>
      <Button
        color="blue"
        icon
        labelPosition="left"
        onClick={handleDownloadCSV}
      >
        <Icon name="download" />
        Download Credentials
      </Button>
      </div>
    </Segment>
  );
};

export default CredentialsDisplay;
