// ExecutionStatusTracker.jsx
import React, { useState, useEffect } from 'react';
import { Card, Icon, Step, Container, Header } from 'semantic-ui-react';
import { SuccessModal, ErrorModal } from './CustomMessageModal';
import axios from 'axios';
import { commercialCloud } from '../../Config/appConstant';
import config from '../../Config/Config';

const ExecutionStatusTracker = ({ executionArn, selectedCloud, token,getResponseData,message,errorMsg,errorModelOpen }) => {
  const [executionStatus, setExecutionStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  
  const fetchExecutionStatus = async () => {
    try {
      const selectedUrl = localStorage.getItem("selectedOrg") === commercialCloud ? config.apiendpoint : config.govCloudApi
      const response = await axios.get(`${selectedUrl}workflow/status`, {
        headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
        },
        params: { executionArn }
      });

      setExecutionStatus(response?.data?.data);
      getResponseData(response?.data?.data)
      
      // Check if execution is complete
      if (response.data.data.status === 'SUCCEEDED') {
        setShowSuccessModal(true);
      } else if (response.data.data.status === 'FAILED') {
        setShowErrorModal(true);
      } else if (['RUNNING', 'IN_PROGRESS'].includes(response.data.data.status)) {
        // Continue polling if execution is still running
        setTimeout(fetchExecutionStatus, 5000);
      }

    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || 'Failed to fetch status';
      setError(errorMessage);
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (executionArn) {
      fetchExecutionStatus();
    }
    else {
      setLoading(false);
      setError(errorMsg);
      setShowErrorModal(true)
    }
  }, []);

  const getStepIcon = (status) => {
    switch (status) {
      case 'COMPLETED':
        return { name: 'check circle', color: 'green' };
      case 'FAILED':
        return { name: 'times circle', color: 'red' };
      case 'IN_PROGRESS':
        return { name: 'spinner', color: 'blue', loading: true };
      default:
        return { name: 'circle outline', color: 'grey' };
    }
  };

  if (loading) {
    return (
      <Container textAlign="center" style={{ padding: '2em' }}>
        <Icon loading name="spinner" size="huge" />
        <Header as="h3">Loading execution status...</Header>
      </Container>
    );
  }

  return (
    <>
      <Card fluid style={{boxShadow:"none"}}>
        <Card.Content>
          
          <Card.Description>
            <Step.Group fluid  size="mini">
                
              {executionStatus?.steps.map((step, index) => (
                <Step 
                  key={index}
                  completed={step.status === 'COMPLETED'}
                  active={step.status === 'IN_PROGRESS'}
                  disabled={!step.startTime}
                >
                  <Icon {...getStepIcon(step.status)} />
                  <Step.Content>
                    <Step.Title>{step.name}</Step.Title>
                    <Step.Description style={{textAlign:"left"}}>
                      <span >{step.status}</span>
                      {step.error && (
                        <div style={{ color: 'red', fontSize: '0.9em' }}>
                          {step.error.error}: {step.error.cause}
                        </div>
                      )}
                    </Step.Description>
                  </Step.Content>
                </Step>
              ))}
            </Step.Group>
          </Card.Description>
          {/* <Card.Meta style={{textAlign:"left"}}>
            Execution ARN: 
            <p>{executionArn}</p>
          </Card.Meta > */}
        </Card.Content>
      </Card>

      <SuccessModal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        message={message || "Workflow execution completed successfully."}
      />

      <ErrorModal
        open={showErrorModal}
        onClose={() => {
          setShowErrorModal(false)
          errorModelOpen()
        }}
        message={error || "Workflow execution failed. Please check the details above."}
      />
    </>
  );
};
export default ExecutionStatusTracker;
