export const tableHeader = [
    {
        key: 'RuleId',
        text: 'Rule ID',
        value: 'RuleId',
    },
    {
        key: 'RuleName',
        text: 'Rule Name',
        value: 'RuleName',
    },
    {
        key: 'Exception',
        text: 'Exception',
        value: 'Exception'
    },
    {
        key: 'ExceptionStatus',
        text: 'Status',
        value: 'ExceptionStatus'
    },
    {
        key: 'ExceptionRegion',
        text: 'Region',
        value: 'ExceptionRegion'
    },
    {
        key: 'ApprovalDate',
        text: 'Approval Date',
        value: 'ApprovalDate'
    },
    {
        key:'LastRecertificationDate',
        text:'Recertification Date',
        value:'LastRecertificationDate'
    },
    {
        key: 'ExpiryDate',
        text: 'Expiry Date',
        value: 'ExpiryDate'
    },
    {
        key: 'ServiceNowTicketId',
        text: 'Service Now ID',
        value: 'ServiceNowTicketId'
    },
    {
        key: 'Severity',
        text: 'Severity',
        value: 'Severity',
    }
]
export const dataPerPageOptions = [
    { key: '1', text: '5', value: 5 },
    { key: '2', text: '10', value: 10 },
    { key: '3', text: '20', value: 20 },
    { key: '4', text: '30', value: 30 },
    { key: '5', text: '50', value: 50 },
    { key: '6', text: '100', value: 100 },
    { key: '7', text: '200', value: 200 },
    { key: '8', text: '300', value: 300 },
    { key: '9', text: '400', value: 400 },
    { key: '10', text: '500', value: 500 }
]

export const pieChartDefaultValue = {
    title: {
        text: '',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}:{c}({d}%)'
    },
    series: [
        {
            name: 'Exception Status',
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            data: [],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0,0,0,0.5)'
                }
            },
            label: {
                show: true,
                formatter: '({d}%)\n{name|{b}}\n{time|{c}}',
                minMargin: 5,
                lineHeight: 15,
                rich: {
                    time: {
                        fontSize: 10,
                        color: '#000',
                    }
                },
            }
        }
    ]
}

export const barChartDefaultValue = {
    title: {
        text: '',
        left: 'center'
    },
    xAxis: {
        type: 'category',
        data: []
    },
    yAxis: {
        type: 'value'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        formatter: 'Rule Name: {b} <br/> No of exception(s): {c}'
    },
    series: [
        {
            data: [],
            type: 'bar',
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }
    ]
}
export const severityKey = ["Medium", "Low", "Info", "Critical", "High"]